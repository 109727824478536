import { Sanity$RowContent } from '@otovo/shared/types/sanityTypes';
import { PortableText } from '@portabletext/react';
import Badge from '@otovo/shared/components/Badge/Badge';
import LinkButton from '@otovo/shared/rainbow/LinkButton/LinkButton';
import SanityPortableTextComponents from '@otovo/shared/components/Sanity/SanityPortableTextComponents';

type Props = {
  children: Sanity$RowContent[] | string;
};

const HeroLead = ({ children }: Props) => {
  return (
    <div className="mb-3 space-y-1 text-[18px] text-base leading-relaxed">
      {typeof children === 'string' ? (
        children
      ) : (
        <PortableText
          value={children}
          components={{
            ...SanityPortableTextComponents,
            block: {
              normal: ({ children: textChildren }) => (
                <div className="text-base lg:text-[18px] lg:text-lg">
                  {textChildren}
                </div>
              ),
            },
            marks: {
              badge: ({ children: content }: { children: React.ReactNode }) => (
                <Badge
                  text={content}
                  className="mr-1 inline px-2 py-1 text-sm normal-case"
                />
              ),
              callToActionButton: ({ children: content, value }) => (
                <div className="mt-6">
                  <LinkButton href={value.link?.href} rel={value.link?.rel}>
                    {content}
                  </LinkButton>
                </div>
              ),
            },
          }}
        />
      )}
    </div>
  );
};

export default HeroLead;
