import { useEffect, useState } from 'react';

const easeOutQuad = (t) => t * (2 - t);

const useCountAnimation = (value: number, duration = 1000): [number] => {
  const [count, setCount] = useState(0);

  useEffect(() => {
    const startMs = new Date().getTime();
    let animationLength = 0;

    function anim() {
      const nowMs = new Date().getTime();
      animationLength = nowMs - startMs;
      // Progress eventually reaches "1" (100%), which ends the animation.
      const progress = easeOutQuad(Math.min(1, animationLength / duration));
      const newVal = value * progress;
      setCount(newVal);

      if (newVal !== value) {
        requestAnimationFrame(anim);
      }
    }
    anim();
  }, [value, duration]);

  return [count];
};

export default useCountAnimation;
