import { FormattedMessage as T } from 'react-intl';

import { cn } from '../../lib/classNames';
import m from './messages';

const Badge = ({
  text,
  className,
}: {
  text?: string | React.ReactNode;
  className?: string;
}) => {
  return (
    <span
      className={cn(
        'block max-w-fit rounded-sm bg-peach-50 px-4 py-2 text-base font-normal capitalize text-grey-90',
        className,
      )}
    >
      {text || (
        <span>
          <T {...m.new} />!
        </span>
      )}
    </span>
  );
};

export default Badge;
