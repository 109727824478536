import {
  Sanity$GridItem,
  Sanity$GridStylingOptions,
} from '@otovo/shared/types/sanityTypes';

import SectionWrapper from '../../SectionWrapper';
import GridCards from './GridCards';
import ImageItems from './GridImages';

type Props = {
  heading?: string;
  description?: string;
  items: Sanity$GridItem[];
  stylingOptions: Sanity$GridStylingOptions;
};

const ContentGrid = ({
  heading,
  description,
  items,
  stylingOptions,
}: Props) => {
  const { itemStyle, colorScheme } = stylingOptions;
  const sectionColor = itemStyle === 'image' ? colorScheme : 'white';
  return (
    <SectionWrapper colorScheme={sectionColor}>
      {(heading || description) && (
        <div className="col-span-full mb-4 max-w-2xl">
          <h2 className="mb-2 text-2xl font-medium md:mb-0 md:text-3xl">
            {heading}
          </h2>
          {description && <p>{description}</p>}
        </div>
      )}
      <div className="col-span-12 w-full">
        {itemStyle === 'image' && (
          <ImageItems items={items} stylingOptions={stylingOptions} />
        )}

        {itemStyle === 'card' && (
          <GridCards items={items} stylingOptions={stylingOptions} />
        )}
      </div>
    </SectionWrapper>
  );
};

export default ContentGrid;
