import { cn } from '../../lib/classNames';

type Props = {
  width: string;
  children?: React.ReactNode;
  className?: string;
};

const Bar = ({ width, children, className }: Props) => {
  return (
    <div
      className={cn(
        'mr-auto flex h-12 items-center pl-2 opacity-100',
        className,
      )}
      style={{
        width,
      }}
    >
      {children}
    </div>
  );
};

export default Bar;
