import { FormattedMessage as T } from 'react-intl';
import { useGlobalConfig } from '../../hooks/configContext';
import sharedMessages from '../Translations/messages';
import Bar from './Bar';
import m from './messages';

type BarWithLabelProps = {
  years: number;
  widthPercent: number;
  backgroundColor: string;
  label: React.ReactNode;
};

const BarWithLabel = ({
  years,
  widthPercent,
  label,
  backgroundColor,
}: BarWithLabelProps) => {
  return (
    <>
      <p className="text-base">{label}</p>
      <Bar className={backgroundColor} width={`${widthPercent}%`}>
        <p className="text-base font-semibold text-lilac-90">
          <T
            {...sharedMessages.numYears}
            values={{
              years,
            }}
          />
        </p>
      </Bar>
    </>
  );
};

const colors = ['bg-lilac-10', 'bg-lilac-30', 'bg-lilac-50'];

const getColorScale = (uniqueYears: number[]): string[] => {
  const fillCount = Math.max(uniqueYears.length - colors.length, 0);
  const fillValue = colors[colors.length - 1];
  return [...colors, ...new Array(fillCount).fill(fillValue)];
};

const BatteryGuaranteeChart = () => {
  const { guarantee } = useGlobalConfig();

  const years = {
    standard: guarantee.industryStandardGuarantee,
    ownership: guarantee.ownershipDuration,
    leasing: guarantee.batteryLeasingDuration,
  };

  const sortedUniqueYears = Array.from(
    new Set([years.standard, years.ownership, years.leasing]),
  ).sort((a, b) => a - b);

  const colorScale = getColorScale(sortedUniqueYears);

  const colorScaleIndex = {
    standard: sortedUniqueYears.indexOf(years.standard),
    ownership: sortedUniqueYears.indexOf(years.ownership),
    leasing: sortedUniqueYears.indexOf(years.leasing),
  };

  const longestGuarantee = sortedUniqueYears[sortedUniqueYears.length - 1];
  const baseWidth = 100 / longestGuarantee;

  return (
    <div className="flex min-h-[192px] items-center overflow-hidden md:min-h-[256px]">
      <div className="w-full">
        <div className="mb-1">
          <BarWithLabel
            label={<T {...m.industryStandardBar} />}
            years={years.standard}
            widthPercent={baseWidth * years.standard}
            backgroundColor={colorScale[colorScaleIndex.standard]}
          />
        </div>
        <div className="mb-1 mt-3">
          <BarWithLabel
            label={
              years.leasing ? (
                <T {...sharedMessages.productNameOwnership} />
              ) : (
                <T {...m.otovoCustomersBar} />
              )
            }
            years={years.ownership}
            widthPercent={baseWidth * years.ownership}
            backgroundColor={colorScale[colorScaleIndex.ownership]}
          />
        </div>
        {!!years.leasing && (
          <div className="mb-1 mt-3">
            <BarWithLabel
              label={<T {...sharedMessages.productNameLeasing} />}
              years={years.leasing}
              widthPercent={baseWidth * years.leasing}
              backgroundColor={colorScale[colorScaleIndex.leasing]}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default BatteryGuaranteeChart;
