import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  Transition,
} from '@headlessui/react';
import ChevronIcon from '@otovo/shared/components/Icons/ChevronIcon/ChevronIcon';
import SanityPortableTextComponents from '@otovo/shared/components/Sanity/SanityPortableTextComponents';
import { Sanity$AccordionFAQ } from '@otovo/shared/types/sanityTypes';
import { PortableText } from '@portabletext/react';
import cx from 'classnames';

type Props = {
  question: Sanity$AccordionFAQ;
};

const FAQItem = ({ question }: Props) => {
  return (
    <Disclosure>
      {({ open }) => (
        <>
          <DisclosureButton
            className={cx(
              `${open ? 'rounded-t-lg' : 'rounded-lg'}`,
              'w-full bg-lilac-5 p-6 text-left text-[18px] font-semibold',
              'focus:outline-blue-70',
            )}
          >
            <div className="flex items-center justify-between">
              {question.title}
              <div
                className={cx(
                  'flex aspect-square items-center justify-center',
                  'mx-3 h-11 w-11 rounded-full bg-peach-50 p-1 text-base font-medium text-grey-90',
                  'hover:bg-peach-60 focus:outline-none focus:ring-2 focus:ring-peach-80',
                  'active:bg-peach-60 active:ring-2 active:ring-inset active:ring-peach-80',
                )}
              >
                <ChevronIcon open={open} className="w-7" />
              </div>
            </div>
          </DisclosureButton>
          <Transition
            as="div"
            enter="transition duration-100 ease-out"
            enterFrom="transform scale-95 opacity-0"
            enterTo="transform scale-100 opacity-100"
            leave="transition duration-75 ease-out"
            leaveFrom="transform scale-100 opacity-100"
            leaveTo="transform scale-95 opacity-0"
          >
            <DisclosurePanel
              className="rounded-b-lg border-t border-lilac-25 bg-lilac-5 p-6"
              static
            >
              <PortableText
                value={question.body}
                components={SanityPortableTextComponents}
              />
            </DisclosurePanel>
          </Transition>
        </>
      )}
    </Disclosure>
  );
};

export default FAQItem;
