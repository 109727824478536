import { Sanity$CampaignPage } from '../../types/sanityTypes';
import blocksToString from './blocksToString';

function getTitleFromSanityCampaignPage(
  sanityCampaignPage: Sanity$CampaignPage,
): string {
  return typeof sanityCampaignPage.headline === 'string'
    ? sanityCampaignPage.headline
    : blocksToString(sanityCampaignPage.headline);
}

export default getTitleFromSanityCampaignPage;
