import { FormattedMessage as T } from 'react-intl';
import m from '@otovo/shared/components/PurchaseModelComparison/messages';
import Lock from '../illustrations/icons/lock.svg';
import ShoppingBag from '../illustrations/icons/shopping-bag.svg';
import ShieldCheck from '../illustrations/icons/shield-check.svg';

const SellingPointsLeasing = () => {
  return (
    <div className="space-y-10 text-grey-90 ">
      <div className="w-full">
        <h3 className="mb-2 text-lg font-medium ">
          <Lock className="mr-2 inline-block h-7 w-7 align-text-bottom" />
          <T {...m.leasingFreezePriceHeading} />
        </h3>
        <p className="text-base text-grey-60 ">
          <T {...m.leasingFreezePriceDescription} />
        </p>
      </div>
      <div className="w-full">
        <h3 className="mb-2 text-lg font-medium ">
          <ShoppingBag className="mr-2 inline-block h-7 w-7 align-text-bottom" />
          <T {...m.leasingEasyBuyoutHeading} />
        </h3>
        <p className="text-base text-grey-60 ">
          <T {...m.leasingEasyBuyoutDescription} />
        </p>
      </div>
      <div className="w-full">
        <h3 className="mb-2 text-lg font-medium ">
          <ShieldCheck className="mr-2 inline-block h-7 w-7 align-text-bottom" />
          <T {...m.leasingLongWarrantyHeading} />
        </h3>
        <p className="text-base text-grey-60">
          <T {...m.leasingLongWarrantyDescription} />
        </p>
      </div>
    </div>
  );
};

export default SellingPointsLeasing;
