type Props = {
  condition: boolean;
  wrap: (arg1: JSX.Element) => JSX.Element;
  children: JSX.Element;
};

const ConditionalWrap = ({ condition, wrap, children }: Props) =>
  condition ? wrap(children) : children;

export default ConditionalWrap;
