import { Sanity$AccordionFAQ } from '@otovo/shared/types/sanityTypes';
import { RowContext } from './types';

export function createRowContext(
  index: number,
  rowContext: {
    faqItems?: Sanity$AccordionFAQ[];
  },
): RowContext {
  const isWhiteRow = index % 2 !== 0;

  const context: RowContext = {
    isWhiteRow,
    rowIndex: index,
    ...rowContext,
  };

  return context;
}
