import log from 'loglevel';
import React, { ReactNode, isValidElement } from 'react';
import { Sanity$TextMarkDefs } from '../../types/sanityTypes';
import RainbowLink from '../RainbowLink/RainbowLink';

type Props = {
  mark: Sanity$TextMarkDefs;
  children:
    | string
    | null
    | undefined
    | Array<string>
    | ReactNode
    | Array<string | ReactNode>; // not certian that we can enforce string type
};

function isBlankLink(mark) {
  return mark._type === 'link' && mark.target === '_blank';
}

function getRel(mark) {
  const blankLinkRel = 'noopener noreferrer';

  if (isBlankLink(mark)) {
    return [mark.rel, blankLinkRel].filter(Boolean).join(' ');
  }
  return mark.rel || undefined;
}

const SanityLink = ({ children, mark }: Props) => {
  const rel = getRel(mark);
  const target = isBlankLink(mark) ? mark.target : undefined;
  let linkSlug;

  if (mark._type === 'link') {
    linkSlug = mark.href;
  }

  if (mark._type === 'documentLink') {
    // WARN: This obviously only works with supportArticles. TODO: Upgrade Navi. Hopefully this fixes issue with relative links
    linkSlug = `/support/${mark.slug.current}`;
  }

  if (!linkSlug) {
    log.warn('Link is not defined for node', { children, mark });
    return null;
  }

  if (typeof children === 'string') {
    return (
      <RainbowLink href={linkSlug} rel={rel}>
        {children}
      </RainbowLink>
    );
  }

  if (Array.isArray(children)) {
    const reactElement = children.find((child) => {
      return Array.isArray(child) && isValidElement(child[0]);
    });

    if (reactElement) {
      return (
        <RainbowLink href={linkSlug} rel={rel}>
          {reactElement}
        </RainbowLink>
      );
    }

    return (
      <RainbowLink href={linkSlug} rel={rel} target={target}>
        {children.reduce((acc, next) => `${acc} ${next}`, '')}
      </RainbowLink>
    );
  }

  log.warn('Expected children to be string or array, got', children);
  return null;
};

export default SanityLink;
