import { defineMessages } from 'react-intl';

const I18N_NAMESPACE = 'SanityFAQAccordion';

export default defineMessages({
  heading: {
    id: `${I18N_NAMESPACE}.heading`,
    defaultMessage: 'FAQ',
  },
});
