import { Sanity$Video } from '../../types/sanityTypes';
import ExternalVideo, { getYouTubeID } from './ExternalVideo';

type Props = {
  sanityVideo: Sanity$Video;
};

const YouTubeVideo = ({ sanityVideo }: Props) => {
  const videoID = getYouTubeID(sanityVideo.url);
  if (!videoID) {
    return null;
  }
  return (
    <>
      <ExternalVideo
        src={`https://www.youtube.com/embed/${videoID}?rel=0&modesbBranding=1&showinfo=0&controls=0`}
        title={sanityVideo.title}
        allowFullScreen
      />
      <p className="mt-2 text-lg">{sanityVideo.description}</p>
    </>
  );
};

export default YouTubeVideo;
