import { Sanity$Section } from '@otovo/shared/types/sanityTypes';
import dynamic from 'next/dynamic';
import ElementPlaceholder from './ElementPlaceholder';
import BlockContentRow from './components/BlockContentRow/BlockContentRow';
import ContentGrid from './components/ContentGrid/ContentGrid';
import LogoSection from './components/LogoSection/LogoSection';
import { RowContext } from './types';

const HubspotFormSection = dynamic(
  () => import('./components/HubspotFormSection/HubspotFormSection'),
);
const TrustpilotSection = dynamic(() => import('./TrustpilotSection'));
const ReferralSignup = dynamic(() => import('./ReferralSignup'));
const TestimonialSection = dynamic(
  () => import('./components/TestimonialSection/TestimonialSection'),
);

const PriceRow = dynamic(
  () => import('../ProductPage/components/PriceRow/PriceRow'),
);
const AddressSearchBulletSection = dynamic(
  () => import('../ProductPage/components/AddressSearchBulletSection'),
);
const RelatedProducts = dynamic(
  () => import('../ProductPage/components/RelatedProducts'),
);

export function rowResolver(
  row: Sanity$Section,
  rowContext: RowContext,
): React.ReactNode {
  if (row._type === 'rowWrapper') {
    return (
      <BlockContentRow
        key={row._key}
        content={row.rowContent}
        context={rowContext}
      />
    );
  }
  if (row._type === 'dynamicPlaceholder') {
    return (
      <ElementPlaceholder
        key={row._key}
        placeholder={row.placeholder}
        context={rowContext}
      />
    );
  }

  if (row._type === 'contentGrid') {
    return (
      <ContentGrid
        key={row._key}
        heading={row.heading}
        description={row.description}
        items={row.items}
        stylingOptions={row.stylingOptions}
      />
    );
  }

  if (row._type === 'logoSection') {
    return (
      <LogoSection
        key={row._key}
        heading={row.heading}
        description={row.description}
        logos={row.logos}
      />
    );
  }

  if (row._type === 'trustpilotSection') {
    return (
      <TrustpilotSection
        key={row._key}
        heading={row.heading}
        description={row.description}
      />
    );
  }
  if (row._type === 'productSection') {
    return <PriceRow key={row._key} content={row} />;
  }
  if (row._type === 'addressStepsSection') {
    return (
      <AddressSearchBulletSection
        key={row._key}
        content={row}
        context={rowContext}
      />
    );
  }
  if (row._type === 'relatedProductsSection') {
    return <RelatedProducts key={row._key} content={row} />;
  }

  if (row._type === 'referralSignupSection') {
    return <ReferralSignup key={row._key} content={row} />;
  }
  if (row._type === 'hubspotFormSection') {
    return <HubspotFormSection key={row._key} form={row.formType[0]} />;
  }
  if (row._type === 'testimonialSection') {
    return <TestimonialSection key={row._key} content={row} />;
  }

  // @ts-expect-error 2339
  throw new Error(`Unknown row type ${row._type}`);
}
