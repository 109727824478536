const O_GRAY_20 = 'rgba(67, 74, 84, 0.4)';

const Quote = (props: any) => (
  <svg viewBox="0 0 144 123.98" width="1em" height="1em" {...props}>
    <path
      d="M62.06 55.62C62.06 78.2 59.51 93.49 44 109c-16.49 16.49-43 15-43 15V99.15q15.71 0 22.35-8.16T30 65.91V52H0V0h55.43a249.76 249.76 0 0 1 6.63 55.62zm81.94 0c0 22.58-3.5 39.88-18 54.38-15 15-43 14-43 14V99.15q15.7 0 22.35-8.16T112 65.91V52H81V0h55.77Q144 31.46 144 55.62z"
      fill={O_GRAY_20}
    />
  </svg>
);

export default Quote;
