import { useRouter } from 'next/router';

import { useGlobalConfig } from '@otovo/shared/hooks/configContext';
import { logToSentry } from '@otovo/shared/lib/sentry';
import { Cloud$Interest } from '@otovo/shared/types/cloudApi';
import { Otovo$Locale } from '@otovo/shared/types/otovoweb';
import { Sanity$ContentPlaceholder } from '@otovo/shared/types/sanityTypes';

import AddressSearchBar from '@otovo/shared/components/InterestRegistration/AddressSearch/AddressSearchBar';
import { handleInterestCreated } from '@otovo/shared/lib/handleInterestCreated/handleInterestCreated';
import { createQueryParamsFromMatchedPartner } from '@otovo/shared/lib/handleInterestCreated/partnerQueryParamHelpers';

import {
  ADDRESS_SEARCH_FIELD_PLACEHOLDER,
  FAQ_PLACEHOLDER,
  OTOVO_GUARANTEE_BATTERY_PLACEHOLDER,
  OTOVO_GUARANTEE_PLACEHOLDER,
  SOLAR_SAVE_CLUB_PROMO_PLACEHOLDER,
} from '@otovo/shared/constants/otovoweb';

import BatteryGuaranteePlaceholder from '@otovo/shared/components/OtovoGuarantee/BatteryGuaranteePlaceholder';
import PurchaseModelComparison from './PurchaseModelComparison/PurchaseModelComparison';
import SectionWrapper from './SectionWrapper';
import FAQ from './components/FAQ/FAQ';
import OtovoGuarantee from './components/OtovoGuarantee/OtovoGuarantee';
import { RowContext } from './types';

type Props = {
  placeholder: Sanity$ContentPlaceholder;
  context: RowContext;
};

const ElementPlaceholder = ({ placeholder, context }: Props) => {
  const { BU_CONFIG } = useGlobalConfig();
  const { ...restRouterParams } = useRouter();
  const locale = restRouterParams.locale as Otovo$Locale;

  const interestCreatedArgs = {
    locale: locale || BU_CONFIG.locale,
    redirectBaseUrl: BU_CONFIG.BREEZE_REDIRECT_BASE_URL,
    queryParams: createQueryParamsFromMatchedPartner(BU_CONFIG.matchedPartner),
  };

  switch (placeholder) {
    case ADDRESS_SEARCH_FIELD_PLACEHOLDER: {
      const { isWhiteRow, mainHardwareType } = context;

      const AddressSearchWrapper = ({
        children,
      }: {
        children: React.ReactNode;
      }) => {
        if (isWhiteRow) {
          return <div className="px-4 pb-3">{children}</div>;
        }
        return <div className="bg-lilac-5 px-4 pb-3">{children}</div>;
      };
      if (context.rowIndex > 0) {
        return (
          <AddressSearchWrapper>
            <SectionWrapper>
              <div className="col-span-full">
                <AddressSearchBar
                  locale={locale}
                  mainHardwareType={mainHardwareType}
                  submissionMethod="javascript"
                  onInterestCreated={(interest: Cloud$Interest) => {
                    handleInterestCreated({
                      interest,
                      ...interestCreatedArgs,
                    });
                  }}
                />
              </div>
            </SectionWrapper>
          </AddressSearchWrapper>
        );
      }
      return (
        <AddressSearchBar
          locale={locale}
          mainHardwareType={mainHardwareType}
          submissionMethod="javascript"
          onInterestCreated={(interest: Cloud$Interest) => {
            handleInterestCreated({
              interest,
              ...interestCreatedArgs,
            });
          }}
        />
      );
    }
    case FAQ_PLACEHOLDER: {
      return <FAQ />;
    }
    case SOLAR_SAVE_CLUB_PROMO_PLACEHOLDER:
      return <PurchaseModelComparison />;
    case OTOVO_GUARANTEE_PLACEHOLDER:
      return <OtovoGuarantee />;
    case OTOVO_GUARANTEE_BATTERY_PLACEHOLDER:
      return <BatteryGuaranteePlaceholder />;
    default:
      logToSentry(`Placeholder type <${placeholder}> not recognized`, {
        placeholder,
        context,
      });
      return null;
  }
};

export default ElementPlaceholder;
