import { defineMessages } from 'react-intl';

const I18N_NAMESPACE = 'PurchaseModelComparison';

export default defineMessages({
  heading: {
    id: `${I18N_NAMESPACE}.heading`,
    defaultMessage: 'You decide how to go solar',
  },
  description: {
    id: `${I18N_NAMESPACE}.description`,
    defaultMessage:
      'Otovo ensures you with the best quality panels for your roof, with installation included. Which way to go solar is up to you.',
  },
  wordLeasing: {
    id: `${I18N_NAMESPACE}.wordLeasing`,
    defaultMessage: 'Leasing',
  },
  wordOwnership: {
    id: `${I18N_NAMESPACE}.wordOwnership`,
    defaultMessage: 'Ownership',
  },
  tabsAltDescription: {
    id: `${I18N_NAMESPACE}.tabsAltDescription`,
    defaultMessage: 'Compare the ways to go solar',
  },
  leasingIntroduction: {
    id: `${I18N_NAMESPACE}.leasingIntroduction`,
    defaultMessage:
      'A monthy subscription with no upfront cost. Otovo is responsible for maintaining your installation for 20 years. In the meantime, you can buy it out if you want.',
  },
  ownershipIntroduction: {
    id: `${I18N_NAMESPACE}.ownershipIntroduction`,
    defaultMessage:
      'Pay for the installation upfront. You own the installation from day one.',
  },
  fromPrice: {
    id: `${I18N_NAMESPACE}.fromPrice`,
    defaultMessage: 'from',
  },
  perMonthSymbol: {
    id: `${I18N_NAMESPACE}.perMonthSymbol`,
    defaultMessage: 'mo',
  },
  learnMoreAboutLeasing: {
    id: `${I18N_NAMESPACE}.learnMoreAboutLeasing`,
    defaultMessage: 'Learn more about leasing.',
  },
  scrollToTopButton: {
    id: `${I18N_NAMESPACE}.scrollToTopButton`,
    defaultMessage: 'Get a price estimate for your roof',
  },
  leasingSaveMoneyHeading: {
    id: `${I18N_NAMESPACE}.uspLeasingSaveMoneyHeading`,
    defaultMessage: 'Save money from day one',
  },
  leasingSaveMoneyDescription: {
    id: `${I18N_NAMESPACE}.uspLeasingSaveMoneyDescription`,
    defaultMessage:
      'Reduce the size of your energy bills. As years go by and energy prices increase, your cost will remain the same!',
  },
  leasingFlexibilityHeading: {
    id: `${I18N_NAMESPACE}.leasingFlexibilityHeading`,
    defaultMessage: 'Flexibility',
  },
  leasingFlexibilityDescription: {
    id: `${I18N_NAMESPACE}.leasingFlexibilityDescription`,
    defaultMessage:
      'Want to upgrade or buy out your system? No problem! Our leasing offer gives you a lot of options.',
  },
  leasingFreezePriceHeading: {
    id: `${I18N_NAMESPACE}.leasingFreezePriceHeading`,
    defaultMessage: 'Lock in low prices',
  },
  leasingFreezePriceDescription: {
    id: `${I18N_NAMESPACE}.leasingFreezePriceDescription`,
    defaultMessage:
      'As years go by and energy prices increase, your cost will remain the same!',
  },

  leasingEasyBuyoutHeading: {
    id: `${I18N_NAMESPACE}.leasingEasyBuyoutHeading`,
    defaultMessage: 'Easy buyout',
  },
  leasingEasyBuyoutDescription: {
    id: `${I18N_NAMESPACE}.leasingEasyBuyoutDescription`,
    defaultMessage:
      'Every month you earn a bonus that makes buying out the system cheaper and cheaper',
  },
  leasingLongWarrantyHeading: {
    id: `${I18N_NAMESPACE}.leasingLongWarrantyHeading`,
    defaultMessage: '20 years of warranty',
  },
  leasingLongWarrantyDescription: {
    id: `${I18N_NAMESPACE}.leasingLongWarrantyDescription`,
    defaultMessage:
      'Otovo monitors and maintains the installation so that you don’t have to worry about it.',
  },
  leasingSystemHealthHeading: {
    id: `${I18N_NAMESPACE}.leasingSystemHealthHeading`,
    defaultMessage: 'System health updates',
  },
  leasingSystemHealthDescription: {
    id: `${I18N_NAMESPACE}.leasingSystemHealthDescription`,
    defaultMessage:
      "Get your production report in the Otovo app. If something goes wrong, we'll let you know.",
  },
  ownershipROIHeading: {
    id: `${I18N_NAMESPACE}.ownershipROIHeading`,
    defaultMessage: 'Great return of investment',
  },
  ownershipROIDescription: {
    id: `${I18N_NAMESPACE}.ownershipROIDescription`,
    defaultMessage:
      'Since you pay for the installation upfront, you’ll never have to pay any interest or subscription fees.',
  },
  ownershipFullControlHeading: {
    id: `${I18N_NAMESPACE}.ownershipFullControlHeading`,
    defaultMessage: 'Full control',
  },
  ownershipFullControlDescription: {
    id: `${I18N_NAMESPACE}.ownershipFullControlDescription`,
    defaultMessage:
      'You own the installation and is free to do whatever you want with it.',
  },
  ownershipLongWarrantyHeading: {
    id: `${I18N_NAMESPACE}.ownershipLongWarrantyHeading`,
    defaultMessage: '10 years of warranty',
  },
  ownershipLongWarrantyDescription: {
    id: `${I18N_NAMESPACE}.ownershipLongWarrantyDescription`,
    defaultMessage:
      'Otovos warranties are best in class. It’s both longer and covers more than most of our competitors.',
  },
  standaloneBatteryOwnershipLongWarrantyHeading: {
    id: `${I18N_NAMESPACE}.standaloneBatteryOwnershipLongWarrantyHeading`,
    defaultMessage: '10 years of warranty',
  },
  standaloneBatteryOwnershipLongWarrantyDescription: {
    id: `${I18N_NAMESPACE}.standaloneBatteryOwnershipLongWarrantyDescription`,
    defaultMessage:
      'Otovos warranties are best in class. It’s both longer and covers more than most of our competitors.',
  },
  standaloneBatteryIndependenceHeading: {
    id: `${I18N_NAMESPACE}.standaloneBatteryIndependenceHeading`,
    defaultMessage: 'Energy independence',
  },
  standaloneBatteryIndependenceDescription: {
    id: `${I18N_NAMESPACE}.standaloneBatteryIndependenceDescription`,
    defaultMessage:
      'Your solar energy can replace the expensive energy produced by the grid. Stored it in your battery, it will make you less dependent on the  grid, and help lower your bills. ',
  },
  standaloneBatteryStorageHeading: {
    id: `${I18N_NAMESPACE}.standaloneBatteryStorageHeading`,
    defaultMessage: 'Storage',
  },
  standaloneBatteryStorageDescription: {
    id: `${I18N_NAMESPACE}.standaloneBatteryStorageDescription`,
    defaultMessage:
      'With a battery you can store the energy produced by the sun during the day, and smartly use it later. Even when the sun is not shining. ',
  },
  standaloneBatteryLeasingLongWarrantyHeading: {
    id: `${I18N_NAMESPACE}.standaloneBatteryLeasingLongWarrantyHeading`,
    defaultMessage: '10 years of warranty',
  },
  standaloneBatteryLeasingLongWarrantyDescription: {
    id: `${I18N_NAMESPACE}.standaloneBatteryLeasingLongWarrantyDescription`,
    defaultMessage:
      'Otovo monitors and maintains the installation so that you don’t have to worry about it.',
  },
});
