import cx from 'classnames';
import ChevronSVG from './chevron.svg';

type Props = {
  className?: string;
  color?: string;
  open?: boolean;
};

const ChevronIcon = ({ className = '', open, color }: Props) => {
  return (
    <ChevronSVG
      color={color || 'currentColor'}
      className={cx(
        'transition-all',
        open ? 'rotate-90 transform' : '',
        className || 'h-6 w-6',
      )}
    />
  );
};

export default ChevronIcon;
