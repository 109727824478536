import { Sanity$TextBlock } from '../../types/sanityTypes';

type Defaults = {
  removeNonText?: boolean;
};

const defaults: Defaults = { removeNonText: true };

function blocksToString(
  blocks: Array<Sanity$TextBlock>,
  opts: Defaults = {},
): string {
  const options = { ...defaults, ...opts };
  return blocks
    .map((block) => {
      if (block._type !== 'block' || !block.children) {
        return options.removeNonText ? '' : `[${block._type} block]`;
      }

      return block.children.map((child) => child.text).join('');
    })
    .join('\n\n');
}

export default blocksToString;
