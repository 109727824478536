import { FormattedMessage as T } from 'react-intl';

import SectionWrapper from '../../../../storefront/components/SanityLandingPage/SectionWrapper';
import BatteryGuaranteeChart from './BatteryGuaranteeChart';
import m from './messages';

const BatteryGuaranteePlaceholder = () => {
  return (
    <SectionWrapper>
      <div
        className="md:col-span-6 md:pr-10 lg:pr-24"
        data-testid="battery-guarantee-placeholder"
      >
        <div>
          <h2 className="mb-5 text-3xl font-medium">
            <T {...m.guaranteeHeading} />
          </h2>
          <p className="text-base">
            <T {...m.guaranteeDescriptionStandaloneBattery} />
          </p>
        </div>
      </div>
      <div className="mt-4 md:col-span-6 md:mt-0">
        <BatteryGuaranteeChart />
      </div>
    </SectionWrapper>
  );
};

export default BatteryGuaranteePlaceholder;
