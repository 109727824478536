import { defineMessages } from 'react-intl';

const I18N_NAMESPACE = 'Sticker';

export default defineMessages({
  new: {
    id: `${I18N_NAMESPACE}.new`,
    defaultMessage: 'new',
  },
});
