import { useRouter } from 'next/router';
import useSWRImmutable from 'swr/immutable';
import { useGlobalConfig } from '../../../hooks/configContext';
import { getSanityClient } from '../../../lib/sanity/sanityClient';
import { logSentryError, logToSentry } from '../../../lib/sentry';
import { Otovo$Locale } from '../../../types/otovoweb';
import { Sanity$AccordionFAQ } from '../../../types/sanityTypes';
import { getAccordionFAQsQuery } from './sanityQueries';

type ResponseType = {
  data: Array<Sanity$AccordionFAQ> | undefined; // When data from SWR is loading, its undefined
  error: Error;
};

const fetcher = (url: string) => getSanityClient().fetch(url);

export function useSanityFAQs(
  initialData?: ResponseType['data'],
  tag?: string,
  fetchAllItems?: boolean,
): ResponseType['data'] {
  const config = useGlobalConfig();
  const { locale } = useRouter() as { locale: Otovo$Locale };

  const query = getAccordionFAQsQuery(
    config.BU_CONFIG,
    locale,
    tag,
    fetchAllItems,
  );

  const { data, error }: ResponseType = useSWRImmutable(query, fetcher, {
    // @ts-expect-error 2769
    initialData,
  });

  if (error?.name === 'ClientError') {
    // This means something went wrong with the Sanity client
    logSentryError(error);
    return [];
  }
  if (data === null) {
    // This means Sanity returned an unexpected response.
    logToSentry(
      'useSanityFAQ: Unexpected response from Sanity, check GROQ query for errors',
      { query, config },
    );

    // Some other error, most likely client side (loss of network etc) - fail silently
    return [];
  }
  return data;
}
