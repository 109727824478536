type Props = {
  children: React.ReactNode;
};

const UnorderedListItem = ({ children }: Props) => (
  <ul>
    <p className="my-2 flex">
      <svg
        width="28"
        height="29"
        viewBox="0 0 28 29"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="mr-3 h-6 w-6 flex-shrink-0"
        aria-hidden="true"
      >
        <circle cx="14" cy="14.5" r="14" fill="#E6E9FF" />
        <path
          d="M9 15.5L12 18.5L19 11.5"
          stroke="#404D69"
          strokeWidth="2"
          strokeLinecap="square"
          strokeLinejoin="round"
        />
      </svg>

      <span>{children}</span>
    </p>
  </ul>
);

export default UnorderedListItem;
