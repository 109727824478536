import { AnchorHTMLAttributes } from 'react';
import Spinner from '../../components/LoadingScreens/Spinner';
import { cn } from '../../lib/classNames';

type ButtonVariant = 'peach' | 'blue';

const variantClasses: Record<NonNullable<ButtonVariant>, string> = {
  peach: 'bg-peach-50 text-grey-90 hover:bg-peach-60 focus:ring-grey-90',
  blue: 'bg-blue-60 text-white hover:bg-blue-50 hover:text-white focus:ring-blue-30 focus:text-white',
};

type LinkButtonProps = {
  variant?: ButtonVariant;
  loading?: boolean;
  className?: string;
} & AnchorHTMLAttributes<HTMLAnchorElement>;

/**
 * A button that looks like a link
 *
 * Should only be used for external links as it uses an anchor tag, not the Next Link component.
 */
const LinkButton = ({
  variant = 'peach',
  loading,
  children,
  className,
  ...props
}: LinkButtonProps) => {
  return (
    <a
      type="a"
      className={cn(
        'rounded-full px-3.5 py-2 text-center text-base font-medium outline-none focus:ring-1',
        'disabled:bg-grey-10 disabled:text-muted',
        variantClasses[variant],
        className,
      )}
      {...props}
    >
      {loading ? <Spinner size="w-5" color="text-grey-90" /> : children}
    </a>
  );
};

export default LinkButton;
