import { useGlobalConfig } from '@otovo/shared/hooks/configContext';
import m from '@otovo/shared/components/OtovoGuarantee/messages';
import sharedMessages from '@otovo/shared/components/Translations/messages';
import { FormattedMessage as T } from 'react-intl';
import UnorderedListItem from '@otovo/shared/components/Sanity/UnorderedListItem';
import SectionWrapper from '../../SectionWrapper';

const YearsText = ({ years }: { years: number }) => (
  <p className="text-sm">
    <T
      {...sharedMessages.numYears}
      values={{
        years,
      }}
    />
  </p>
);

const OtovoGuarantee = () => {
  const { guarantee } = useGlobalConfig();
  const years = {
    standard: guarantee.industryStandardGuarantee,
    ownership: guarantee.ownershipDuration,
    leasing: guarantee.leasingDuration,
  };

  const longestGuarantee = Math.max(...Object.values(years));

  return (
    <SectionWrapper>
      <div className="col-span-6" data-testid="guarantee-placeholder">
        <div>
          <h2 className="mb-2 text-2xl font-medium md:mb-6 md:text-3xl">
            <T {...m.guaranteeHeading} />
          </h2>
          <p>
            <T {...m.guaranteeDescriptionShort} />
          </p>
        </div>

        <div className="my-4 space-y-5">
          <UnorderedListItem>
            <T {...m.guaranteeFact1} />
          </UnorderedListItem>
          <UnorderedListItem>
            <T {...m.guaranteeFact2} />
          </UnorderedListItem>
        </div>
      </div>

      <div className="-order-1 col-span-6 mt-12 space-y-3 md:mt-0 md:space-y-6">
        <div>
          <p className="text-sm opacity-80">
            <T {...m.industryStandardBar} />
          </p>
          <div className="h-14 w-1/4 rounded-lg bg-peach-10" />
        </div>
        <div>
          <p className="text-sm opacity-80">
            <T {...m.directPurchaseBar} />
          </p>
          <div className="h-14 w-1/2 rounded-lg bg-peach-30" />
        </div>
        <div>
          <p className="text-sm opacity-80">
            <T {...m.leasingBar} />
          </p>
          <div className="relative h-14 w-11/12 rounded-lg bg-peach-40">
            <svg
              className="absolute -right-9 -top-9 h-11 -rotate-90"
              fill="none"
              viewBox="0 0 58 58"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill="#FE8F65"
                d="M27 0h30v8H27zM23.657 17 44.87 38.213l-6.038 6.039-21.214-21.213zM8 27v30H0V27z"
              />
            </svg>
          </div>
        </div>
        <div className="flex w-11/12 justify-between border-t border-grey-20 pt-2">
          <YearsText years={0} />
          <YearsText years={longestGuarantee / 2} />
          <YearsText years={longestGuarantee} />
        </div>
      </div>
    </SectionWrapper>
  );
};

export default OtovoGuarantee;
