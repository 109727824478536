import { Sanity$Break } from '../../types/sanityTypes';

type Props = {
  node: Sanity$Break;
};

const BreakRenderer = ({ node }: Props) => {
  if (node.visibility === 'visible') {
    return <hr className="m-16 text-grey-10" />;
  }
  return <hr className="m-16 text-transparent" />;
};

export default BreakRenderer;
