import { defineMessages } from 'react-intl';

const I18N_NAMESPACE = 'Currency';

export default defineMessages({
  shortKroneSymbol: {
    id: `${I18N_NAMESPACE}.shortKroneSymbol`,
    defaultMessage: 'krone',
  },

  shortEuroSymbol: {
    id: `${I18N_NAMESPACE}.shortEuroSymbol`,
    defaultMessage: '€',
  },

  longEuroSymbol: {
    id: `${I18N_NAMESPACE}.longEuroSymbol`,
    defaultMessage: 'euros',
  },

  kWh: {
    id: `${I18N_NAMESPACE}.kWh`,
    defaultMessage: 'kWh',
  },

  month: {
    id: `${I18N_NAMESPACE}.month`,
    defaultMessage: 'month',
  },

  year: {
    id: `${I18N_NAMESPACE}.year`,
    defaultMessage: 'year',
  },
});
