import { Tab } from '@headlessui/react';
import { purchaseModelCode } from '@otovo/shared/cloudEnums';
import m from '@otovo/shared/components/PurchaseModelComparison/messages';
import { useGlobalConfig } from '@otovo/shared/hooks/configContext';
import { Sanity$PricingConfig } from '@otovo/shared/types/sanityTypes';
import cx from 'classnames';
import { useState } from 'react';
import { FormattedMessage as T } from 'react-intl';
import Leasing from './Leasing';
import Ownership from './Ownership';

function PurchaseModelTabs() {
  return (
    <Tab.List className="space flex flex-wrap space-y-3  text-grey-90 md:flex-nowrap md:space-y-0">
      <Tab
        className={({ selected }) =>
          cx(
            'h-full w-full rounded-full  py-4  text-lg md:w-1/2 md:text-xl',
            selected && 'bg-grey-90 text-lilac-20',
            !selected && 'text-grey-90/50 ring-1 ring-grey-90/50',
          )
        }
      >
        <T {...m.wordLeasing} />
      </Tab>
      <div className="md:mx-8 lg:mx-16" />
      <Tab
        className={({ selected }) =>
          cx(
            'h-full w-full rounded-full py-4 text-lg sm:text-xl md:w-1/2',
            selected && 'bg-grey-90  text-lilac-20',
            !selected && 'text-grey-90/50 ring-1 ring-grey-90/50',
          )
        }
      >
        <T {...m.wordOwnership} />
      </Tab>
    </Tab.List>
  );
}

function convertToIndex(
  bothPurchaseModelsAreEnabled: boolean,
  defaultPurchaseModel: Sanity$PricingConfig['defaultPurchaseModel'],
) {
  const LEASING_INDEX = 0;
  const NOW_INDEX = 1;
  if (bothPurchaseModelsAreEnabled && defaultPurchaseModel === 'now') {
    return NOW_INDEX;
  }

  return LEASING_INDEX;
}

const PurchaseModelComparison = () => {
  const {
    pricing,
    urls: { leasingLandingPageUrl },
  } = useGlobalConfig();

  const leasingEnabled = !!pricing.enabledPurchaseModels.find(
    (purchaseModel) =>
      purchaseModel.toUpperCase() === purchaseModelCode.LEASING,
  );
  const ownershipEnabled = !!pricing.enabledPurchaseModels.find(
    (purchaseModel) =>
      purchaseModel.toUpperCase() === purchaseModelCode.BUY_NOW,
  );

  const bothPurchaseModelsAreEnabled = leasingEnabled && ownershipEnabled;

  const [selectedIndex, setSelectedIndex] = useState(
    convertToIndex(bothPurchaseModelsAreEnabled, pricing.defaultPurchaseModel),
  );

  return (
    <div className="bg-grey-90">
      <div className="relative mx-6 mb-16 max-w-7xl py-10 sm:py-24 md:grid md:grid-cols-12 md:gap-8 md:py-11 xl:mx-auto xl:w-full xl:px-11">
        <div className="col-span-full max-w-3xl">
          <h2 className="mb-6 text-2xl font-medium text-lilac-20 md:text-3xl xl:text-4xl">
            <T {...m.heading} />
          </h2>
          <p className="mb-4 text-grey-20 sm:text-lg">
            <T {...m.description} />
          </p>
        </div>
        <div className="col-span-full rounded-lg bg-white px-6 py-12 md:px-14">
          <Tab.Group selectedIndex={selectedIndex} onChange={setSelectedIndex}>
            {bothPurchaseModelsAreEnabled && <PurchaseModelTabs />}
            <Tab.Panels className={cx(bothPurchaseModelsAreEnabled && 'pt-14')}>
              {leasingEnabled && (
                <Leasing
                  leasingLandingPageUrl={leasingLandingPageUrl}
                  startingPriceLeasing={pricing.startingPriceLeasing}
                />
              )}
              {ownershipEnabled && (
                <Ownership
                  startingPriceOwnership={pricing.startingPriceOwnership}
                />
              )}
            </Tab.Panels>
          </Tab.Group>
        </div>
      </div>
    </div>
  );
};

export default PurchaseModelComparison;
