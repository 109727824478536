import messages from '@otovo/shared/components/Sanity/SanityFAQAccordion/messages';
import { useSanityFAQs } from '@otovo/shared/components/Sanity/SanityFAQAccordion/useSanityFAQs';
import { FormattedMessage as T } from 'react-intl';
import SectionWrapper from '../../SectionWrapper';
import FAQItem from './FAQItem';

const FAQ = () => {
  const data = useSanityFAQs();
  if (!data) {
    return null;
  }
  return (
    <SectionWrapper>
      <h2
        className="col-span-full mb-6 text-3xl font-medium"
        data-testid="sanity-faq-storefront"
      >
        <T {...messages.heading} />
      </h2>
      {data.map((question) => (
        <div
          key={`accordion-item-${question.title}`}
          className="col-span-6 mb-4 md:mb-0"
        >
          <FAQItem question={question} />
        </div>
      ))}
    </SectionWrapper>
  );
};

export default FAQ;
