import { Sanity$RowContent } from '@otovo/shared/types/sanityTypes';
import { PortableText } from '@portabletext/react';

type Props = {
  children: Sanity$RowContent[] | string;
};

const HeroHeading = ({ children }: Props) => {
  return (
    <h1 className="mb-3 block break-words text-3xl font-medium md:text-4xl">
      {typeof children === 'string' ? (
        children
      ) : (
        <PortableText
          components={{
            marks: {
              gradient: ({
                children: content,
              }: {
                children: React.ReactNode;
              }) => (
                <span className="bg-gradient-to-tr from-[#EA5634] to-[#FBC739] bg-clip-text text-transparent">
                  {content}
                </span>
              ),
            },

            // @ts-expect-error 2322
            block: ({ children: content }: { children: React.ReactNode }) => (
              <span>{content}</span>
            ),

            container: ({
              children: content,
            }: {
              children: React.ReactNode;
            }) => <span>{content}</span>,
          }}
          value={children}
        />
      )}
    </h1>
  );
};

export default HeroHeading;
