import {
  Sanity$GridItem,
  Sanity$GridStylingOptions,
} from '@otovo/shared/types/sanityTypes';

import cx from 'classnames';
import { PortableText } from '@portabletext/react';

import SanityPortableTextComponents from '@otovo/shared/components/Sanity/SanityPortableTextComponents';
import SanityImage from '@otovo/shared/components/Sanity/SanityImage';

type Props = {
  items: Sanity$GridItem[];
  stylingOptions: Sanity$GridStylingOptions;
};

const ImageItems = ({ items, stylingOptions }: Props) => {
  const { itemsPerRow, equalizeImageHeight, imageWidth } = stylingOptions;
  const imgWidth = {
    small: 'w-24',
    medium: 'w-64',
    large: 'w-full max-w-lg',
  }[imageWidth];

  const shouldShowCallToAction = (item: Sanity$GridItem) =>
    !!item.url && !!item.callToAction;

  return (
    <ul
      className={cx(
        'grid w-full grid-cols-1  gap-x-7 gap-y-10 md:gap-y-7',
        `${itemsPerRow.medium === 2 && 'sm:grid-cols-2'}`,
        `${itemsPerRow.medium === 3 && 'sm:grid-cols-3'}`,
        `${itemsPerRow.medium === 4 && 'sm:grid-cols-4'}`,
        `${itemsPerRow.large === 2 && 'lg:grid-cols-2'}`,
        `${itemsPerRow.large === 3 && 'lg:grid-cols-3'}`,
        `${itemsPerRow.large === 4 && 'lg:grid-cols-4'}`,
      )}
    >
      {items.map((item) => {
        return (
          <li
            key={item._key}
            className={cx(
              'relative w-full max-w-xl list-none  md:mb-0',
              `${shouldShowCallToAction(item) && 'pb-16'}`,
            )}
          >
            <div className="flex-shrink-0 ">
              <div
                className={`${imgWidth} relative aspect-[3/2]`}
                style={{
                  borderRadius: '8px',
                  overflow: 'hidden',
                }}
              >
                <SanityImage
                  image={item.image}
                  alt={item.imageAltText}
                  fill
                  equalizeImageHeight={equalizeImageHeight}
                  sizes="(max-width: 767px) 450px, 380px"
                />
              </div>
            </div>
            {item.heading && (
              <p className="mt-3 text-lg font-medium">{item.heading}</p>
            )}
            {item.description && (
              <div className={`${item.heading ? 'mt-1' : 'mt-3'}`}>
                <PortableText
                  value={item.description}
                  components={SanityPortableTextComponents}
                />
              </div>
            )}
            {shouldShowCallToAction(item) && (
              <div className="absolute bottom-4 left-0 font-semibold text-digital decoration-0">
                <a href={item.url} className="hover:red">
                  {item.callToAction}
                </a>
              </div>
            )}
          </li>
        );
      })}
    </ul>
  );
};

export default ImageItems;
