import QuoteIcon from '../Icons/Quote';

type Props = {
  name?: React.ReactNode;
  quoteText: React.ReactNode;
  withQuoteSymbol?: boolean;
};

const Quote = ({ name, quoteText, withQuoteSymbol = true }: Props) => (
  <>
    <QuoteIcon className="text-md" />
    <blockquote className="mt-2 leading-6">
      {withQuoteSymbol && <span>&quot;</span>}
      {quoteText}
      {withQuoteSymbol && <span>&quot;</span>}
    </blockquote>
    <p className="block text-base">{name}</p>
  </>
);

export default Quote;
