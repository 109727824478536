import {
  Sanity$GridItem,
  Sanity$GridStylingOptions,
} from '@otovo/shared/types/sanityTypes';

import cx from 'classnames';
import { PortableText } from '@portabletext/react';

import ConditionalWrap from '@otovo/shared/components/ImageComponents/ConditionalWrap';
import SanityPortableTextComponents from '@otovo/shared/components/Sanity/SanityPortableTextComponents';
import SanityImage from '@otovo/shared/components/Sanity/SanityImage';

type Props = {
  items: Sanity$GridItem[];
  stylingOptions: Sanity$GridStylingOptions;
};

const GridCards = ({ items, stylingOptions }: Props) => {
  const { itemsPerRow, imageWidth, equalizeImageHeight, colorScheme } =
    stylingOptions;

  const shouldShowCallToAction = (item: Sanity$GridItem) =>
    !!item.url && !!item.callToAction;
  return (
    <ul
      className={cx(
        'grid auto-rows-fr grid-cols-1 gap-4',
        `${itemsPerRow.medium === 2 && 'sm:grid-cols-2'}`,
        `${itemsPerRow.medium === 3 && 'sm:grid-cols-3'}`,
        `${itemsPerRow.medium === 4 && 'sm:grid-cols-4'}`,
        `${itemsPerRow.large === 2 && 'lg:grid-cols-2'}`,
        `${itemsPerRow.large === 3 && 'lg:grid-cols-3'}`,
        `${itemsPerRow.large === 4 && 'lg:grid-cols-4'}`,
      )}
    >
      {items.map((item) => {
        return (
          <li key={item._key} className="list-none">
            <ConditionalWrap
              condition={!!item.url}
              wrap={(children) => (
                <a
                  className={cx(
                    'block h-full rounded-lg no-underline transition-all',
                    'focus:outline focus:outline-2 focus:outline-offset-4 focus:outline-blue-70',
                    'hover:-translate-y-1 hover:scale-[1.02] hover:border-none',
                  )}
                  href={item.url}
                  rel="noopener noreferrer"
                >
                  {children}
                </a>
              )}
            >
              <div
                className={cx(
                  'relative flex h-full flex-col overflow-hidden rounded-lg',
                  `${
                    colorScheme === 'dark'
                      ? 'bg-grey-90 text-lilac-20'
                      : 'bg-lilac-5 text-grey-60'
                  }`,
                  `${shouldShowCallToAction(item) && 'pb-64'}`,
                )}
              >
                <div className={`${imageWidth !== 'large' && 'p-5'}`}>
                  <div className="relative aspect-video">
                    <SanityImage
                      image={item.image}
                      alt={item.imageAltText}
                      equalizeImageHeight={equalizeImageHeight}
                      fill
                      sizes="(max-width: 767px) 320px, 280px"
                    />
                  </div>
                </div>
                <div className="px-5 pb-5 pt-3">
                  {item.heading && (
                    <p className="mt-3 text-lg font-medium">{item.heading}</p>
                  )}
                  {item.description && (
                    <div className={`${item.heading ? 'mt-2' : 'mt-3'}`}>
                      <PortableText
                        value={item.description}
                        components={SanityPortableTextComponents}
                      />
                    </div>
                  )}
                </div>
                {shouldShowCallToAction(item) && (
                  <div className="absolute bottom-4 left-5 font-semibold text-digital decoration-0 ">
                    <ConditionalWrap
                      condition={!item.url}
                      wrap={(children) => (
                        <a type="button" href={item.url}>
                          {children}
                        </a>
                      )}
                    >
                      <p>{item.callToAction}</p>
                    </ConditionalWrap>
                  </div>
                )}
              </div>
            </ConditionalWrap>
          </li>
        );
      })}
    </ul>
  );
};

export default GridCards;
