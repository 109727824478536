import cx from 'classnames';

type Props = {
  colorScheme?: 'white' | 'light' | 'dark';
  children: React.ReactNode;
};

const SectionWrapper = ({ colorScheme = 'white', children }: Props) => {
  const coloredClasses = 'rounded-lg px-6 py-12 md:px-14 md:py-14';

  return (
    <div
      data-testid="storefront-row"
      className={cx(
        'relative mx-6 my-28 max-w-7xl sm:py-24 md:grid md:grid-cols-12 md:gap-8 md:py-11 xl:mx-auto xl:w-full xl:px-11',
        'flex-shrink-0 justify-center',
        `${colorScheme !== 'white' && coloredClasses} `,
        `${colorScheme === 'dark' && 'bg-grey-90  text-lilac-20'} `,
        `${colorScheme === 'light' && 'bg-lilac-20'} `,
      )}
    >
      {children}
    </div>
  );
};

export default SectionWrapper;
