import { PortableTextComponents } from '@portabletext/react';
import { Sanity$Video } from '../../types/sanityTypes';
import BlockQuote from '../Quote/BlockQuote';
import LinkButton from '../../rainbow/LinkButton/LinkButton';
import SanityLink from './SanityLink';
import UnorderedListItem from './UnorderedListItem';
import YouTubeVideo from './YouTubeVideo';
import BreakRenderer from './BreakRenderer';

const SanityPortableTextComponents: PortableTextComponents = {
  marks: {
    textAlign_left: ({ children }) => (
      <span className="text-left"> {children}</span>
    ),
    textAlign_right: ({ children }) => (
      <span className="text-right"> {children}</span>
    ),
    textAlign_center: ({ children }) => (
      <span className="text-center"> {children}</span>
    ),
    textAlign_justify: ({ children }) => (
      <span className="text-justify"> {children}</span>
    ),
    strong: ({ children }) => (
      <strong className="font-semibold">{children}</strong>
    ),
    link: ({ children, value }) => (
      <SanityLink mark={value}> {children}</SanityLink>
    ),
    customHeadingTag: ({ children }) => <>{children}</>, // If not set, heading will be nested as span, not text
    callToActionButton: ({ children, value }) => (
      <LinkButton
        href={value.link?.href}
        rel={value.link?.rel}
        className="mt-2 block w-fit"
      >
        {children}
      </LinkButton>
    ),
  },
  block: {
    h1: ({ children }) => (
      <h2 className="mb-3 break-words text-2xl font-medium md:mb-6 md:text-3xl">
        {children}
      </h2>
    ),
    h2: ({ children }) => (
      <h2 className="mb-3 break-words text-2xl font-medium md:mb-6 md:text-3xl">
        {children}
      </h2>
    ),
    h3: ({ children }) => (
      <h3 className="mb-3 break-words text-2xl font-medium md:mb-6">
        {children}
      </h3>
    ),
    h4: ({ children }) => (
      <h4 className="mb-3 break-words text-xl font-medium md:mb-6">
        {children}
      </h4>
    ),
    h5: ({ children }) => (
      <h5 className="mb-3 break-words text-lg font-medium md:mb-6">
        {children}
      </h5>
    ),
    blockquote: ({ children }) => (
      <div className="mt-8">
        <BlockQuote quoteText={children} withQuoteSymbol={false} />
      </div>
    ),
    normal: ({ children }) => <div className="text-base">{children}</div>,
  },
  list: {
    bullet: ({ children }) => <ul className="mt-2">{children}</ul>,
  },
  listItem: {
    bullet: ({ children }) => <UnorderedListItem>{children}</UnorderedListItem>,
    number: ({ children }) => <UnorderedListItem>{children}</UnorderedListItem>,
  },
  types: {
    video: ({ value: sanityVideo }: { value: Sanity$Video }) => (
      <YouTubeVideo sanityVideo={sanityVideo} />
    ),
    image: () => null,
    typeformWidget: () => null,
    contentBreak: ({ value }) => <BreakRenderer node={value} />,
  },
};

export default SanityPortableTextComponents;
