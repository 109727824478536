import { defineMessages } from 'react-intl';

const I18N_NAMESPACE = 'OtovoGuarantee';

export default defineMessages({
  guaranteeHeading: {
    id: `${I18N_NAMESPACE}.guaranteeHeading`,
    defaultMessage: 'Otovo Guarantee: we have your back',
  },

  guaranteeDescription: {
    id: `${I18N_NAMESPACE}.guaranteeDescription`,
    defaultMessage:
      'We ensure quality in everything we do. As our proof to you, our extended warranty comes included in the price. While most of our competitors choose to offer no more than the minimum 5 years, we go further:',
  },
  guaranteeDescriptionStandaloneBattery: {
    id: `${I18N_NAMESPACE}.guaranteeDescriptionStandaloneBattery`,
    defaultMessage:
      'We ensure quality in everything we do. As our proof to you, our extended warranty comes included in the price. While most of our competitors choose to offer no more than the minimum 5 years, we go further:',
  },

  guaranteeDescriptionShort: {
    id: `${I18N_NAMESPACE}.guaranteeDescriptionShort`,
    defaultMessage:
      'With our unique guarantee, your solar consumption is worry-free. While most of our competitors choose to offer no more than the minimum 5 years, we go further:',
  },

  guaranteeFact1: {
    id: `${I18N_NAMESPACE}.guaranteeFact1`,
    defaultMessage: 'For 20 years for leasing customers.',
  },

  guaranteeFact2: {
    id: `${I18N_NAMESPACE}.guaranteeFact2`,
    defaultMessage: 'For 10 years for direct purchase.',
  },

  guaranteeFactLeasing: {
    id: `${I18N_NAMESPACE}.guaranteeFactLeasing`,
    defaultMessage: '{years} for leasing customers.',
  },

  guaranteeFactOwnership: {
    id: `${I18N_NAMESPACE}.guaranteeFactOwnership`,
    defaultMessage: '{years} for direct purchase.',
  },

  industryStandardBar: {
    id: `${I18N_NAMESPACE}.industryStandardBar`,
    defaultMessage: 'Industry standard',
  },

  otovoCustomersBar: {
    id: `${I18N_NAMESPACE}.otovoCustomersBar`,
    defaultMessage: 'For Otovo Customers',
  },

  directPurchaseBar: {
    id: `${I18N_NAMESPACE}.directPurchaseBar`,
    defaultMessage: 'With direct purchase',
  },

  leasingBar: {
    id: `${I18N_NAMESPACE}.leasingBar`,
    defaultMessage: 'For leasing customers',
  },

  otovoCertifiedImageAlt: {
    id: `${I18N_NAMESPACE}.otovoCertifiedImageAlt`,
    defaultMessage: 'A badge reading Otovo Certified',
  },

  learnMore: {
    id: `${I18N_NAMESPACE}.learnMore`,
    defaultMessage: 'Learn more about our warranties',
  },
});
