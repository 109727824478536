import {
  Sanity$AccordionFAQ,
  Sanity$MainHardwareType,
  Sanity$Section,
} from '@otovo/shared/types/sanityTypes';
import { createRowContext } from './lib';
import { rowResolver } from './rowResolver';

type Props = {
  rows: Sanity$Section[];
  rowContext?: {
    faqItems?: Sanity$AccordionFAQ[];
    mainHardwareType?: Sanity$MainHardwareType;
  };
};

const ContentRowList = ({ rows, rowContext = {} }: Props) => {
  return (
    <>
      {rows.map((row, index) => {
        const currentRow = rowResolver(
          row,
          createRowContext(index, rowContext),
        );

        return currentRow;
      })}
    </>
  );
};

export default ContentRowList;
