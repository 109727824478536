import { Sanity$LogoItem } from '@otovo/shared/types/sanityTypes';
import { cn } from '@otovo/shared/lib/classNames';
import SanityImage from '@otovo/shared/components/Sanity/SanityImage';
import SectionWrapper from '../../SectionWrapper';

type Props = {
  heading?: string;
  description?: string;
  logos: Sanity$LogoItem[];
};

const LogoSection = ({ heading, description, logos }: Props) => {
  const Wrapper = ({
    logo,
    children,
  }: {
    logo: Sanity$LogoItem;
    children: React.ReactNode;
  }) => {
    const rel = logo.rel ? `${logo.rel} noreferrer` : 'noreferrer';
    const target = '_blank';

    return logo.url ? (
      <a
        key={logo._key}
        href={logo.url}
        target={target}
        rel={rel}
        className={cn(
          'flex rounded-lg bg-lilac-5 px-5 py-8 transition-all',
          'focus:outline focus:outline-2 focus:outline-offset-1 focus:outline-blue-70',
          'hover:-translate-y-1 hover:scale-[1.03] hover:outline hover:outline-offset-1 hover:outline-blue-70 ',
        )}
      >
        {children}
      </a>
    ) : (
      <div className="rounded-lg bg-white py-10">{children}</div>
    );
  };

  return (
    <SectionWrapper>
      <div className="col-span-full">
        {heading && <h2 className="text-3xl font-medium ">{heading}</h2>}
        {description && <p>{description}</p>}
        <div
          className={cn(
            'mt-6 lg:mt-10',
            `grid grid-cols-1 gap-3 md:grid-cols-3`,
            `${logos.length > 4 ? 'lg:grid-cols-5' : ''}`,
          )}
        >
          {logos.map((logo) => {
            return (
              <Wrapper key={logo._key} logo={logo}>
                <div className="align-center flex h-12 w-full justify-center">
                  <SanityImage
                    image={logo.image}
                    alt={logo.title}
                    sizes="200px"
                  />
                </div>
              </Wrapper>
            );
          })}
        </div>
      </div>
    </SectionWrapper>
  );
};

export default LogoSection;
