import { Sanity$Image } from '@otovo/shared/types/sanityImage';
import { Sanity$RowContent } from '@otovo/shared/types/sanityTypes';
import { PortableText } from '@portabletext/react';

import SanityImage from '@otovo/shared/components/Sanity/SanityImage';
import SanityPortableTextComponents from '@otovo/shared/components/Sanity/SanityPortableTextComponents';

import SectionWrapper from '../../SectionWrapper';
import { RowContext } from '../../types';

type Props = {
  content: Sanity$RowContent[];
  context: RowContext;
};

const BlockContentRow = ({ content, context }: Props) => {
  const { isWhiteRow } = context;
  // @ts-expect-error 2322
  const image: Sanity$Image = content?.find((block) => block._type === 'image');

  if (!image) {
    return (
      <SectionWrapper>
        <div className="col-span-2" />
        <div className="align-center col-span-8 flex  flex-col justify-center">
          <PortableText
            value={content}
            components={SanityPortableTextComponents}
          />
        </div>
        <div className="col-span-2" />
      </SectionWrapper>
    );
  }

  if (isWhiteRow) {
    return (
      <SectionWrapper colorScheme="white">
        <div className="align-center col-span-6 flex max-w-lg flex-col justify-center">
          <PortableText
            value={content}
            components={SanityPortableTextComponents}
          />
        </div>
        <div className="my-10 md:hidden" />
        <div className="col-span-6 overflow-hidden">
          <SanityImage image={image} sizes="(max-width: 767px) 80vw, 480px" />
        </div>
      </SectionWrapper>
    );
  }

  return (
    <SectionWrapper colorScheme="light">
      <div className="col-span-6 overflow-hidden">
        <SanityImage image={image} sizes="(max-width: 767px) 80vw, 480px" />
      </div>
      <div className="my-10 md:hidden" />
      <div className="align-center col-span-6 flex max-w-lg flex-col justify-center">
        <PortableText
          value={content}
          components={SanityPortableTextComponents}
        />
      </div>
    </SectionWrapper>
  );
};

export default BlockContentRow;
