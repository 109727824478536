import getSanityLocaleDocumentQuery from '../../../lib/getSanityLocaleDocumentQuery';
import { Cloud$BusinessUnitConfig } from '../../../types/cloudApi';
import { Otovo$Locale } from '../../../types/otovoweb';

// This filter items by tag, or if no tag is provided, filter out items with tags
const getSanityFAQTagFilter = (tag?: string) =>
  tag ? `&& '${tag}' in tags[]` : `&& !defined(tags)`;

export function getAccordionFAQsQuery(
  buConfig: Cloud$BusinessUnitConfig,
  locale: Otovo$Locale | undefined,
  tag?: string,
  fetchAllItems?: boolean,
): string {
  const localeQuery = getSanityLocaleDocumentQuery({ locale, buConfig });
  const tagFilter = getSanityFAQTagFilter(tag);

  const { market } = buConfig;
  return /* groq */ `*[_type == 'faq' && activeFrom <= $now
    ${localeQuery}
    && references("market-${market}") ${tagFilter}] | order(priority asc, title asc){
      title, slug, body
    }${fetchAllItems ? '' : '[0...8]'}`; // Conditionally fetch the first 8 items
}
