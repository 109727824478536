import { useEffect, useRef, useState } from 'react';
import { observe } from '../../lib/intersectionObserver';
import { logToSentry } from '../../lib/sentry';
import Spinner from '../LoadingScreens/Spinner';

export const getYouTubeID = (url: string): string | null | undefined => {
  const youtubeRegex =
    /(?:youtube(?:-nocookie)?\.com\/(?:[^/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
  const videoID = url.match(youtubeRegex);
  if (!videoID || !Array.isArray(videoID) || typeof videoID[1] !== 'string') {
    logToSentry('Could not find valid YouTube video id');
    return null;
  }
  return videoID[1];
};

type Props = {
  title: string;
  src: string;
  allowFullScreen?: boolean;
};

const ExternalVideo = ({ title, src, allowFullScreen }: Props) => {
  const [doLoad, setDoLoad] = useState(false);
  const [loaded, setLoaded] = useState(false);

  const videoContainer: { current: null | HTMLDivElement } = useRef(null);

  useEffect(() => {
    observe({
      element: videoContainer.current,
      isIntersectingCallback: () => setDoLoad(true),
    });
  }, [videoContainer]);

  return (
    <div
      className="my-6 flex w-full items-center justify-center"
      ref={videoContainer}
    >
      {doLoad && (
        <iframe
          className=" aspect-video w-full"
          title={title}
          src={src}
          // @ts-expect-error 2322
          onLoad={setLoaded}
          allowFullScreen={allowFullScreen}
        />
      )}
      {!loaded && <Spinner className="h-9 w-9" />}
    </div>
  );
};

ExternalVideo.defaultProps = {
  allowFullScreen: true,
};
export default ExternalVideo;
