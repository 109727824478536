import { Cloud$BusinessUnitConfig } from '../types/cloudApi';
import { Otovo$Locale } from '../types/otovoweb';

type Args = {
  locale: Otovo$Locale | undefined;
  buConfig: Cloud$BusinessUnitConfig;
};
export default function getSanityLocaleDocumentQuery({
  buConfig,
  locale,
}: Args) {
  if (!locale) {
    return '';
  }
  const shouldQueryForDocumentsWithLocale =
    buConfig.supported_locales.length > 1;
  // base-language documents that belong to a multi-lingual business unit,
  // have a __i18n_lang property set to an empty string.
  if (!shouldQueryForDocumentsWithLocale) {
    return '';
  }
  const defaultLocale = buConfig.locale;
  if (locale === defaultLocale) {
    // The __i18n_lang property is usually excluded from documents written in
    // the default locale. However, if a person duplicates a document with the
    // __i18n_lang set, it'll be part of the new document—even if the person
    // changes the locale back to the default.
    // There's a third option too, where a person duplicates a page from a
    // market with only one locale. In that case, the __i18n_lang__ won't even
    // be present for the default document.
    //
    // This query handles all three cases.
    return `&& (__i18n_lang == "" || __i18n_lang == "${defaultLocale}" || !defined(__i18n_lang))`;
  }
  return `&& __i18n_lang == "${locale}"`;
}
