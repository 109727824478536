import getMetaImageUrl, {
  IMAGE_HEIGHT,
  IMAGE_WIDTH,
} from '../../lib/sanity/getMetaImageUrl';
import { Sanity$Image } from '../../types/sanityImage';
import { Sanity$Meta, Sanity$TextBlock } from '../../types/sanityTypes';
import Head from '../Head/Head';
import blocksToString from './blocksToString';

type Props = {
  meta?: Sanity$Meta;
  defaultTags: {
    title: string;
    description: string | Array<Sanity$TextBlock>;
    image?: Sanity$Image;
  };
};

const MetaTags = ({ meta, defaultTags }: Props) => {
  const isPreview = process.env.NEXT_PUBLIC_VERCEL_ENV === 'preview';
  const fallbackDescription = Array.isArray(defaultTags.description)
    ? blocksToString(defaultTags.description)
    : defaultTags.description;

  const imageUrl = getMetaImageUrl(meta?.metaImage || defaultTags?.image);

  return (
    <Head
      title={meta?.metaTitle || defaultTags.title}
      description={meta?.metaDescription || fallbackDescription}
      noindex={meta?.noindex || isPreview}
      canonicalURLOverride={meta?.canonicalURLOverride || null}
      image={imageUrl || undefined}
      imageWidth={IMAGE_WIDTH || undefined}
      imageHeight={IMAGE_HEIGHT || undefined}
    />
  );
};

export default MetaTags;
