import { FormattedMessage as T } from 'react-intl';
import m from '@otovo/shared/components/PurchaseModelComparison/messages';
import BarChart from '../illustrations/icons/bar-chart.svg';
import ShieldCheck from '../illustrations/icons/shield-check.svg';
import ShoppingBag from '../illustrations/icons/shopping-bag.svg';

const SellingPointsOwnership = () => {
  return (
    <div className="space-y-10 text-grey-90 ">
      <div className="w-full">
        <h3 className="mb-2 text-lg font-medium">
          <BarChart className="mr-2 inline-block h-7 w-7 align-text-bottom" />
          <T {...m.ownershipROIHeading} />
        </h3>
        <p className="text-base text-grey-60">
          <T {...m.ownershipROIDescription} />
        </p>
      </div>
      <div className="w-full">
        <h3 className="mb-2 text-lg font-medium">
          <ShoppingBag className="mr-2 inline-block h-7 w-7 align-text-bottom" />
          <T {...m.ownershipFullControlHeading} />
        </h3>
        <p className="text-base text-grey-60">
          <T {...m.ownershipFullControlDescription} />
        </p>
      </div>
      <div className="w-full">
        <h3 className="mb-2 text-lg font-medium">
          <ShieldCheck className="mr-2 inline-block h-7 w-7 align-text-bottom" />
          <T {...m.ownershipLongWarrantyHeading} />
        </h3>
        <p className="text-base text-grey-60">
          <T {...m.ownershipLongWarrantyDescription} />
        </p>
      </div>
    </div>
  );
};

export default SellingPointsOwnership;
