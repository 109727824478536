import imageUrlBuilder from '@sanity/image-url';
import { Sanity$Image } from '../../types/sanityImage';
import { getSanityClientConfig } from './sanityClient';

export const IMAGE_WIDTH = 1200;
export const IMAGE_HEIGHT = 630;

/**
 * Image size recommendations from
 *  https://developers.facebook.com/docs/sharing/best-practices
 * and https://iamturns.com/open-graph-image-size/
 */

const getMetaImageUrl = (image?: Sanity$Image | null): any | null => {
  if (!image) {
    return null;
  }

  const { asset } = image;
  const urlBuilder = imageUrlBuilder(getSanityClientConfig());

  return urlBuilder
    .image(asset)
    .width(IMAGE_WIDTH)
    .height(IMAGE_HEIGHT)
    .quality(50)
    .auto('format')
    .url();
};

export const getProductSEOImages = (
  image?: Sanity$Image | null,
): string[] | null => {
  if (!image) {
    return null;
  }

  const { asset } = image;
  const urlBuilder = imageUrlBuilder(getSanityClientConfig());

  const sizes = [
    { aspectRatioWidth: 16, aspectRatioHeight: 9, width: IMAGE_WIDTH },
    { aspectRatioWidth: 4, aspectRatioHeight: 3, width: IMAGE_WIDTH },
    { aspectRatioWidth: 1, aspectRatioHeight: 1, width: IMAGE_WIDTH },
  ];

  const urls = sizes.map((size) => {
    const height = Math.round(
      size.width / (size.aspectRatioWidth / size.aspectRatioHeight),
    );
    return urlBuilder
      .image(asset)
      .width(size.width)
      .height(height)
      .quality(50)
      .url();
  });

  return urls;
};

export default getMetaImageUrl;
