import { defineMessages } from 'react-intl';

const I18N_NAMESPACE = 'shared';

/*
This messages file is for messages shared by several components.
If you want to add a lowercase or  uppercase version, please make a XXXLowercase or XXXUppercase message.
If you want to add several messages that share a theme but are still shared by several components,
please create a subfolder under this folder.
*/

export default defineMessages({
  name: {
    id: `${I18N_NAMESPACE}.name`,
    defaultMessage: 'Name',
  },

  firstAndLastName: {
    id: `${I18N_NAMESPACE}.firstAndLastName`,
    defaultMessage: 'First and Last name',
  },

  phoneNumber: {
    id: `${I18N_NAMESPACE}.phoneNumber`,
    defaultMessage: 'Phone number',
  },

  email: {
    id: `${I18N_NAMESPACE}.email`,
    defaultMessage: 'E-mail',
  },

  address: {
    id: `${I18N_NAMESPACE}.address`,
    defaultMessage: 'Address',
  },

  send: {
    id: `${I18N_NAMESPACE}.send`,
    defaultMessage: 'Send',
  },

  wordStandard: {
    id: `${I18N_NAMESPACE}.wordStandard`,
    defaultMessage: 'Standard',
  },

  wordPremium: {
    id: `${I18N_NAMESPACE}.wordPremium`,
    defaultMessage: 'Premium',
  },

  wordTier3: {
    id: `${I18N_NAMESPACE}.wordTier3`,
    defaultMessage: 'Performance',
  },

  wordUnknown: {
    id: `${I18N_NAMESPACE}.unknown`,
    defaultMessage: 'Unknown',
  },

  wordPanels: {
    id: `${I18N_NAMESPACE}.wordPanels`,
    defaultMessage: '{amount, plural, one {panel} other {panels}}',
  },

  wordStandardPanel: {
    id: `${I18N_NAMESPACE}.wordStandardPanel`,
    defaultMessage: 'Standard {amount, plural, one {panel} other {panels}}',
  },

  wordPremiumPanel: {
    id: `${I18N_NAMESPACE}.wordPremiumPanel`,
    defaultMessage: 'Premium {amount, plural, one {panel} other {panels}}',
  },

  wordTier3Panel: {
    id: `${I18N_NAMESPACE}.wordTier3Panel`,
    defaultMessage: 'Performance {amount, plural, one {panel} other {panels}}',
  },

  wordTier3Color: {
    id: `${I18N_NAMESPACE}.wordTier3Color`,
    defaultMessage: 'black',
  },

  wordPremiumColor: {
    id: `${I18N_NAMESPACE}.wordPremiumColor`,
    defaultMessage: 'black',
  },

  wordStandardColor: {
    id: `${I18N_NAMESPACE}.wordStandardColor`,
    defaultMessage: 'blue',
  },

  wordSmallPackage: {
    id: `${I18N_NAMESPACE}.wordSmallPackage`,
    defaultMessage: 'Small',
  },

  wordEconomicPackage: {
    id: `${I18N_NAMESPACE}.wordEconomicPackage`,
    defaultMessage: 'Economy',
  },

  wordLargePackage: {
    id: `${I18N_NAMESPACE}.wordLargePackage`,
    defaultMessage: 'Large',
  },
  wordSolarPanel: {
    id: `${I18N_NAMESPACE}.wordSolarPanel`,
    defaultMessage: 'Solar {amount, plural, one {panel} other {panels}}',
  },
  wordBattery: {
    id: `${I18N_NAMESPACE}.wordBattery`,
    defaultMessage: 'Battery',
  },
  wordEvCharger: {
    id: `${I18N_NAMESPACE}.wordEvCharger`,
    defaultMessage: 'EV charger',
  },
  wordLoading: {
    id: `${I18N_NAMESPACE}.wordLoading`,
    defaultMessage: 'Loading',
  },
  purchaseModelNow: {
    id: `${I18N_NAMESPACE}.purchaseModelNow`,
    defaultMessage: 'Direct',
  },
  purchaseModelLoan: {
    id: `${I18N_NAMESPACE}.purchaseModelLoan`,
    defaultMessage: 'Solar loan',
  },
  solarSaveMember: {
    id: `${I18N_NAMESPACE}.solarSaveMember`,
    defaultMessage: 'Leasing',
  },
  productNameLeasing: {
    id: `${I18N_NAMESPACE}.productNameLeasing`,
    defaultMessage: 'Leasing',
  },
  productNameOwnership: {
    id: `${I18N_NAMESPACE}.productNameOwnership`,
    defaultMessage: 'Direct payment',
  },
  selectedPanelType: {
    id: `${I18N_NAMESPACE}.selectedPanelType`,
    defaultMessage: 'Chosen panel type',
  },
  amountOfPanels: {
    id: `${I18N_NAMESPACE}.amountOfPanels`,
    defaultMessage: 'Chosen number of panels',
  },
  annualProduction: {
    id: `${I18N_NAMESPACE}.annualProduction`,
    defaultMessage: 'Estimated annual production',
  },
  numYears: {
    id: `${I18N_NAMESPACE}.numYears`,
    defaultMessage: '{years, plural, one {# year} other {# years}}',
  },
  submitForm: {
    id: `${I18N_NAMESPACE}.submitForm`,
    defaultMessage: 'Submit',
  },
  included: {
    id: `${I18N_NAMESPACE}.included`,
    defaultMessage: 'Included',
  },
  notIncluded: {
    id: `${I18N_NAMESPACE}.notIncluded`,
    defaultMessage: 'Not included',
  },
  editPackage: {
    id: `${I18N_NAMESPACE}.editPackage`,
    defaultMessage: 'Edit package',
  },
  editSystem: {
    id: `${I18N_NAMESPACE}.editSystem`,
    defaultMessage: 'Edit system',
  },
  meyerBurgerPanels: {
    id: `${I18N_NAMESPACE}.meyerBurgerPanels`,
    defaultMessage: 'Meyer Burger Module',
  },
});
